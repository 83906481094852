<template>
  <div class="tinymceEditor">
    <div class="editor_title">
      <div class="title_left" @click="rename">
        <div class="save" @click.stop="handleSave">
          <img :src="require('assets/images/disk/Icons/002.png')" alt="" />
        </div>
        <div class="reName" :title="textName + '.docx'">
          {{ textName }}.docx
        </div>
      </div>
      <div class="activeNum">字符数：{{ sum }}</div>
    </div>

    <editor
      ref="tinymce"
      class="editor"
      api-key="no-api-key"
      :init="init"
      :placeholder="placeholderText"
      v-model="content"
      @onFocus="placeholderText = ''"
    />

    <!-- 重命名 -->
    <el-dialog
      title="重命名"
      :visible.sync="renameDialog"
      class="rename"
      :modal="false"
      top="0"
      :before-close="handleCloseRename"
    >
      <div class="content">
        <div class="content-label">文件名</div>
        <input
          type="text"
          v-model="fileNameInput"
          class="content-input"
          @keyup.enter="sureRename"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="footer-btn sure" @click="sureRename">确定</div>
        <div class="footer-btn" @click="handleCloseRename">取消</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { preserveWord } from "api/common";
export default {
  name: "tinymceEditor",
  components: {
    editor: Editor,
  },
  props: {
    returnJoinWord: {},
    Deposit_certificate: {},
  },
  data() {
    return {
      // 富文本的标题名字
      textName: "未命名",
      // 文本内容
      content: "",
      // 字符
      sum: 0,
      // 重命名窗口
      renameDialog: false,
      // 重命名输入框数据
      fileNameInput: "",
      placeholderText: this.Deposit_certificate,
      init: {
        content_style:
          "html {height:100%} #tinymce {position: relative; padding:40px 45px 0;height:90%} #tinymce::-webkit-scrollbar {width:6px;height: 7px;} #tinymce::-webkit-scrollbar-thumb {background-color: #c0c6cf;  border: 1px solid #ff6900; border-radius: 20px;} #tinymce::-webkit-scrollbar-track {box-shadow: inset 0 0 6px #ededed;background-color: #ededed;} #tinymce::after {content: '';display: block;width: 95%;background-color: #fff; height:25px;position: absolute;top: 15px;margin: 0 auto;background-repeat: no-repeat; background-size: 100% 100%; background-image: url(" +
          require("assets/jiao.png") +
          "); transform: translate(-50%);left: 50%;} #tinymce::before {left: 45px;top: 55px;color: #ccc;font-style: italic;} #tinymce:focus::before {display: none;}",
        // placeholder: this.Deposit_certificate,
        plugins: [
          "lists",
          "image",
          "table",
          "code",
          "help",
          "wordcount",
          "paste",
        ],
        language: "zh_CN",
        statusbar: false,
        toolbar:
          "undo redo removeformat bold underline italic strikethrough superscript subscript forecolor align fontselect fontsizeselect formatselect",
        menubar: "view help",
        font_formats: `微软雅黑=微软雅黑;宋体=宋体;黑体=黑体;仿宋=仿宋;楷体=楷体;隶书=隶书;幼圆=幼圆;
          Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black
          =arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS
          =comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia
          ,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=
          tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman
          =times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,g
          eneva;Webdings=webdings;Wingdings=wingdings`,
        paste_enable_default_filters: false,
        paste_preprocess: (plugin, args) => {
          if (
            this.sum + args.content.replace(/<[^>]+>/g, "").length > 50000 ||
            args.content.replace(/<[^>]+>/g, "").length > 50000
          ) {
            this.$message({
              type: "warning",
              message: "内容超出限制",
            });
            args.content = "";
          }
        },
      },
    };
  },
  methods: {
    async handleSave() {
      if (!this.content) {
        return this.$message({
          message: "内容不能为空!",
          duration: 1000,
        });
      }
      this.$store.commit("playLifeLoading", true);
      let form = new FormData();
      form.append("fileName", this.textName + ".docx");
      form.append("content", this.content);
      const res = await preserveWord(form);
      if (res) {
        const blob = new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        let objectUrl = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = objectUrl;
        link.setAttribute("download", this.textName + ".docx");
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
      this.$store.commit("playLifeLoading", false);
    },

    // 打开重命名
    rename() {
      this.renameDialog = true;
      this.fileNameInput = this.textName;
    },

    //  重命名弹窗关闭触发
    handleCloseRename() {
      this.renameDialog = false;
      this.fileNameInput = "";
    },

    //确定重命名
    sureRename() {
      if (/ /.test(this.fileNameInput)) {
        return this.$message({
          type: "error",
          message: "不能有空格字符",
        });
      }
      this.textName = this.fileNameInput;
      this.renameDialog = false;
      this.$emit("handleChangeEditor", [this.content, this.sum, this.textName]);
    },
  },
  watch: {
    content: {
      handler: function (cur) {
        this.sum = cur.replace(/<[^>]+>/g, "");
        this.sum = this.sum.replace(/[\n\r\t\s]/g, "");
        this.sum = this.sum.replace(/&nbsp;/g, "").length;
        this.$emit("handleChangeEditor", [
          this.content,
          this.sum,
          this.textName,
        ]);
      },
    },
    returnJoinWord: {
      handler: function (val) {
        console.log(val);
        if (val && val != "") {
          this.content = val;
        } else {
          this.content = "";
        }
      },
    },
  },

  mounted() {},
  beforeDestroy() {},
  // toolbar: `newdocument bold italic underline strikethrough alignleft aligncenter alignright
  // alignjustify alignnone styleselect formatselect fontselect fontsizeselect cut
  // copy paste outdent indent blockquote undo redo removeformat subscript
  // superscript visualaid insert hr bullist numlist link unlink openlink image
  // charmap charmap pastetext print preview anchor pagebreak spellchecker
  // searchreplace visualblocks visualchars code help fullscreen insertdatetime media
  // nonbreaking save cancel table tabledelete tablecellprops tablemergecells
  // tablesplitcells tableinsertrowbefore tableinsertrowafter tabledeleterow
  // tablerowprops tablecutrow tablecopyrow tablepasterowbefore tablepasterowafter
  // tableinsertcolbefore tableinsertcolafter tabledeletecol rotateleft rotateright
  // flipv fliph editimage imageoptions fullpage ltr rtl emoticons template forecolor
  // backcolor restoredraft insertfile a11ycheck toc quickimage quicktable quicklink`,
};
</script>
<style lang="scss">
.tox-notifications-container {
  display: none;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  .tox-dialog-wrap {
    .tox-dialog.tox-dialog--width-md {
      .tox-dialog__content-js {
        .tox-dialog__body {
          .tox-dialog__body-nav {
            .tox-dialog__body-nav-item.tox-tab {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.tinymceEditor {
  height: 100%;
  flex: 1;
  position: relative;
  background-color:#fff;
  .editor_title {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 10;
    display: flex;
    height: 40px;
    background: #f7f7f7;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .title_left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 170px;
      max-width: 200px;
      height: 40px;
      overflow: hidden;
      background: #ffffff;
      text-indent: 2em;
      padding-right: 20px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;

      .save {
        margin-left: 10px;
        width: 30px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          cursor: pointer;
          background-color: #f1f1f1;
        }
        img {
          display: block;
          width: 20px;
          height: 20px;
        }
      }

      .reName {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .activeNum {
      font-size: $font-size-medium;
      font-family: PingFang SC;
      font-weight: 500;
      color: $color-text-gray;
      margin-right: 13px;
    }
  }

  .editor {
    height: 100%;
  }

  ::v-deep .tox-tinymce {
    border: 0px;
    .tox-editor-container {
      .tox-editor-header {
        .tox-menubar {
          flex-direction: row-reverse;
          background-color: #f7f7f7;
          .tox-mbtn.tox-mbtn--select {
            color: #999999;
          }
        }
      }
      .tox-editor-header {
        .tox-toolbar__primary {
          border-top: 1px solid #eceff1;
          border-bottom: 1px solid #eceff1;
          background: none;

          .tox-toolbar__group {
            .tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke {
              .tox-tbtn__select-label {
                width: 6em;
              }
            }
          }
        }
      }
    }
    // .tox-sidebar-wrap {

    //   .tox-edit-area {
    //    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.1);
    //  }
    // }
  }

  // 重命名弹窗样式
  .rename {
    ::v-deep .el-dialog {
      width: 428px;
      height: 253px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .el-dialog__header {
        padding: 0;
        height: 42px;
        background: #f4f6f7;
        display: flex;
        align-items: center;
        .el-dialog__title {
          margin-left: 14px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 12px;
        }
      }
      .el-dialog__body {
        padding: 50px 30px 0;
        .content {
          display: flex;
          height: 50px;
          align-items: center;
          &-label {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 12px;
          }
          &-input {
            width: 290px;
            height: 50px;
            background: #ffffff;
            border: 1px solid #cecece;
            border-radius: 5px;
            padding-left: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
          }
        }
      }
      .el-dialog__footer {
        display: flex;
        justify-content: flex-end;
        padding: 50px 30px 0 0;
        .dialog-footer {
          display: flex;
          .footer-btn {
            width: 71px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #cecece;
            border-radius: 3px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
          }
          .sure {
            background: #4c8cff;
            color: #ffffff;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
</style>
<style></style>
